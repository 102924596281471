import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Layout, Section } from '../components/common';

import ductTapeImg from '../images/duct-tape.jpg';

const useStyles = makeStyles((theme) => ({
    root: {},
    h1: {
        color: theme.palette.primary.main,
    },
    h2: {
        color: theme.palette.grey[500],
    },
    ductTapeImg: {
        marginTop: theme.spacing(4),
    },
}));

function NotFoundPage({ location }) {
    const classes = useStyles();
    return (
        <Layout
            location={location}
            classes={{ root: classes.root }}
            navBarProps={{
                scrollThreshold: 0,
            }}
        >
            <Section>
                <Typography variant="h1" className={classes.h1}>
                    Page not found
                </Typography>
                <Typography variant="body1" className={classes.h2}>
                    You may have mistyped the address or the page may have
                    moved.
                </Typography>
                <img
                    src={ductTapeImg}
                    alt="Roll of duct tape"
                    className={classes.ductTapeImg}
                />
            </Section>
        </Layout>
    );
}
export default NotFoundPage;

NotFoundPage.propTypes = {
    location: PropTypes.object,
};

NotFoundPage.defaultProps = {
    location: undefined,
};
